//$primary_color: #090;
$primary_color:#8fbf21;
$secondary_color:#f5b1d0;
$add_message_color:#ff6eb0;
$grey_background:#626262;
$green_text:#769d1c;
$red_price:#840008;
$footer_back:#e8e8e8;
$filter_background:#eee;
$product_price:#bd0031;


//$primary_color:#000;
$ginger_color:#bf5700;
$red_color:#bf0000;
$lightblue_color:#007373;
//$lightyellow_color:#fff1bc;
//$lightyellow_color:#686262;
//$lightyellow_color:#d2d2d2;
$lightyellow_color:#f5f5f5;
$lightyellow_button:#f0e8cc;
$lightgrey_color:#c7c7c7;
$primary_background:#dadada;
$hard_grey:#a5a5a5;
//$active_link:#3c0;
$active_link:#00964a;
$active_hover:#8ace73;
$grey_border:#e1e1e1;
$on_primary_color:#fff;
$secondary_background:#fff;
$default_color:#404148;
$dark_grey:#292c2d;
$text_color: #222;
$register_link_color:#ca4646;
$login_link_color:#1A28B3;
$button_hover:#006130;
$grey_zebra: #f5f5f5;
$logo_size: 90px;
$p_top: 10px;
$m_top:20px;
$main-font-size:14px;
$menu_font_size:18px;
$map_height:500px;