@charset "UTF-8";
@font-face {
  font-family: "Evolventa";
  src: url("/fonts/evolventa.ttf"); }

.no_padding {
  padding-left: 0;
  padding-right: 0; }

.sticky {
  position: fixed;
  z-index: 10;
  top: 70px;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.75); }
  .sticky.sticked {
    position: absolute; }

.content-part.no_padding {
  padding: 0; }

.flow {
  overflow: hidden !important; }

.right_product_wrap {
  position: relative; }
  .right_product_wrap .right_product_block {
    padding: 20px 15px;
    background: #fff; }
    .right_product_wrap .right_product_block .cta {
      position: relative; }
      .right_product_wrap .right_product_block .cta .added_message {
        padding: 20px 5px;
        bottom: 30px;
        left: 0;
        max-width: auto; }

.wrapper {
  max-width: 1350px;
  margin: 0 auto; }
  .wrapper.top-wrapper {
    max-width: 1440px; }
  .wrapper .slick-prev {
    left: -10px;
    z-index: 100; }
  .wrapper .slick-next {
    right: -10px;
    z-index: 10; }

body {
  font-family: 'Evolventa' ,Arial,'IBM Plex Sans Condensed', sans-serif;
  min-height: 900px; }
  body a {
    color: #8fbf21; }
    body a:hover, body a:active, body a:focus {
      color: #00964a;
      text-decoration: none; }

body, .content, #content {
  color: #404148; }

.header-container-top {
  background: #8fbf21;
  border-bottom: 2px solid #f5b1d0;
  position: relative;
  font-weight: bold; }

.header-bottom {
  background: #626262; }

.header-menu-top {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .header-menu-top .timetable {
    color: #fff; }
  .header-menu-top a {
    color: #fff;
    text-decoration: none;
    transition: all ease .45s; }
    .header-menu-top a:hover {
      text-decoration: none;
      color: #404148; }
    .header-menu-top a:focus {
      text-decoration: none;
      color: #404148; }
    .header-menu-top a:active {
      text-decoration: none;
      color: #404148; }
  .header-menu-top ul {
    display: inline-block;
    font-size: 14px;
    list-style: none;
    padding: 0;
    margin: 0; }
    .header-menu-top ul li {
      display: inline-block;
      padding: 5px 5px; }

.header-menu-top-right {
  background: #f5b1d0;
  text-align: center;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center; }
  .header-menu-top-right a.login_btn {
    color: #404148;
    display: inline-block;
    padding: 5px 0px;
    transition: all .3s ease;
    flex-grow: 1; }
    .header-menu-top-right a.login_btn:hover {
      color: #1A28B3;
      text-decoration: underline; }
  .header-menu-top-right a.register_btn {
    color: #404148;
    display: inline-block;
    padding: 5px 0px;
    transition: all .3s ease;
    flex-grow: 1; }
    .header-menu-top-right a.register_btn:hover {
      color: #ca4646;
      text-decoration: underline; }

a.logo {
  display: block;
  margin: 0 auto;
  padding-top: 10px; }
  a.logo img {
    width: 100%; }

.search_wrap {
  padding: 30px 0; }
  .search_wrap p {
    position: relative; }
    .search_wrap p input {
      padding: 5px 30px;
      border-radius: 5px;
      border: 1px solid #ccc; }
    .search_wrap p:before {
      position: absolute;
      z-index: 10;
      width: 10px;
      top: 5px;
      right: 0;
      bottom: 0;
      left: 10px;
      content: "";
      font-family: Font Awesome\ 5 Free;
      font-weight: 900;
      color: #ccc; }

.timetable {
  font-size: 14px; }
  .timetable b, .timetable i {
    color: #8fbf21; }

.head_phones {
  padding-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .head_phones .head_phone {
    font-size: 20px;
    color: #fff;
    padding-bottom: 5px;
    line-height: 1;
    margin: 0;
    position: relative; }
    .head_phones .head_phone.lifecell:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: url("/img/lifecell.png") no-repeat;
      background-size: cover;
      left: -25px; }
    .head_phones .head_phone.kyivstar:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: url("/img/kyivstar.png") no-repeat;
      background-size: cover;
      left: -25px; }
    .head_phones .head_phone.vodafone:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: url("/img/vodafone.png") no-repeat;
      background-size: cover;
      left: -25px; }

.head_address {
  padding-top: 20px; }
  .head_address p {
    color: #fff;
    font-size: 20px; }

.head_cart {
  padding-top: 25px;
  padding-bottom: 10px;
  color: #222; }
  .head_cart img {
    width: 45px;
    filter: grayscale(100%);
    transition: all ease .3s; }
  .head_cart div {
    display: inline-block; }
  .head_cart i {
    font-size: 35px;
    transition: color .5s ease; }
  .head_cart p {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    font-weight: bold; }
  .head_cart a {
    color: #222; }
    .head_cart a:hover {
      color: #222;
      text-decoration: none; }
      .head_cart a:hover img {
        filter: grayscale(0%); }
      .head_cart a:hover i {
        color: #8fbf21; }
  .head_cart .badge {
    vertical-align: bottom;
    background: #f5b1d0;
    color: #222;
    position: relative;
    left: -20px; }

.head_callback {
  margin-top: 5px; }
  .head_callback a {
    border: 1px solid #8fbf21;
    border-radius: 3px;
    color: #8fbf21;
    background: transparent;
    padding: 5px 15px;
    transition: all .3s ease;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    cursor: pointer; }
    .head_callback a:hover, .head_callback a:focus, .head_callback a:active {
      background: #8fbf21;
      color: #fff;
      border-color: #8fbf21; }

#search-form .control-label {
  display: none; }

#search-form .tt-menu {
  padding: 10px 5px; }

#search-form div > span {
  float: none !important;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 30px; }
  #search-form div > span input {
    width: 100%;
    height: 44px; }
    #search-form div > span input.loading {
      background: #fff !important; }

.tt-menu .tt-suggestion {
  word-wrap: break-word;
  white-space: normal !important; }

.head_right {
  text-align: center; }

.head_socials {
  display: flex;
  justify-content: center;
  padding-top: 20px; }
  .head_socials a {
    width: 75px;
    height: 70px;
    margin-right: 15px;
    border: 3px solid #fff;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    transition: all 0.5s linear;
    box-shadow: 1px 5px 20px -5px #4c595f; }
    .head_socials a i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      transition: all 0.5s linear;
      color: #8fbf21; }
    .head_socials a:hover {
      background: #8fbf21;
      border: 3px solid #8fbf21;
      box-shadow: 0 0 35px 4px #8fbf21; }
      .head_socials a:hover i {
        color: #fff; }

.head_search_wrap {
  padding-top: 30px; }
  .head_search_wrap .help-block {
    position: absolute;
    top: 0; }

.breadcrumb {
  padding: 13px 15px;
  margin: 0;
  background: #e8e8e8; }
  .breadcrumb li {
    line-height: 1; }
    .breadcrumb li a {
      color: #404148; }
      .breadcrumb li a:hover {
        text-decoration: underline; }
    .breadcrumb li.active {
      font-size: 16px;
      font-weight: bold; }

.btn-wishlist {
  display: none; }

.left-sidebar {
  padding-right: 0px;
  padding-left: 0; }

.search-col {
  padding-left: 0;
  padding-right: 0; }

.search-wrap {
  background: #8fbf21;
  padding: 5px 0; }
  .search-wrap .help-block {
    position: absolute;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 10;
    background: #8fbf21;
    padding: 0 5px; }
  .search-wrap input.tt-input {
    border-radius: 0 !important; }
    .search-wrap input.tt-input.loading {
      background: #fff !important; }

.content-part {
  padding: 0 15px; }

.dropdown .dropdown-menu {
  left: -90px; }

.sliding_block_container {
  background: #8fbf21;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  display: none;
  box-shadow: 0 0 35px 4px #ff6eb0; }

.sliding_block .no_padding {
  padding: 0; }

.sliding_block .input-group input {
  border-radius: 0;
  height: 40px; }

.titler {
  font-size: 33px;
  font-weight: bold;
  text-transform: uppercase;
  color: #769d1c;
  text-align: center;
  margin-bottom: 20px; }

.products_grids .grid .col-md-4 {
  padding: 0 7px; }

.grid {
  margin: 20px 0;
  position: relative; }
  .grid:after {
    content: '.';
    visibility: hidden;
    width: 1px;
    height: 1px;
    clear: both; }

.categories-list.sidebar-widget {
  border-bottom: 0; }

.block-product-list .main_item .main_item_img {
  height: 297px; }

.main_item {
  background: #eee;
  margin-bottom: 15px;
  transition: all linear .45s; }
  .main_item .main_item_img {
    height: 345px; }
    .main_item .main_item_img div {
      display: block;
      height: 100%;
      overflow: hidden; }
    .main_item .main_item_img img {
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      height: auto;
      display: block;
      transition: all linear .45s;
      margin: 0 auto; }
  .main_item .main_item_title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #222;
    padding: 5px 0; }
    .main_item .main_item_title:hover {
      color: #222; }
  .main_item .main_item_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    position: relative; }
    .main_item .main_item_info .main_item_price {
      font-size: 25px;
      font-weight: bold;
      color: #840008; }
    .main_item .main_item_info .main_item_add {
      max-width: 175px;
      padding: 10px;
      display: block;
      width: 100%;
      text-align: center;
      color: #8fbf21;
      border: 2px solid #8fbf21;
      font-weight: bold;
      transition: all linear .45s;
      cursor: pointer; }
      .main_item .main_item_info .main_item_add:hover {
        background: #8fbf21;
        color: #fff;
        box-shadow: 0 0 35px 4px #8fbf21; }
  .main_item:hover {
    background: #e4e4e4; }
    .main_item:hover .main_item_img img {
      transform: scale(1.1); }
  .main_item .added_message {
    padding: 16px 5px;
    border: 2px solid #ff6eb0;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    max-width: 200px;
    width: 100%;
    position: absolute;
    bottom: 20px;
    right: 10px;
    background: #ff6eb0;
    display: none;
    color: #fff;
    box-shadow: 0 0 35px 4px #ff6eb0; }

.main_content_inner {
  background: url("/img/main_content.png") no-repeat;
  background-size: cover;
  text-align: justify;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 40px;
  position: relative; }
  .main_content_inner .wrapper {
    overflow: hidden; }
  .main_content_inner .more_btn {
    text-align: center; }
    .main_content_inner .more_btn span {
      color: #8fbf21;
      font-size: 60px;
      transform: rotate(180deg);
      display: inline-block;
      cursor: pointer;
      padding: 20px 40px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      transition: all linear .45s; }
      .main_content_inner .more_btn span.rotate {
        transform: rotate(0deg); }
      .main_content_inner .more_btn span:hover {
        background: rgba(255, 255, 255, 0.7); }

.main_stok {
  padding: 20px 40px;
  background: #8fbf21; }
  .main_stok ul {
    list-style: none;
    margin: 0; }
    .main_stok ul li {
      display: inline-block;
      margin-right: 15px; }
      .main_stok ul li a {
        color: #fff; }
        .main_stok ul li a:hover {
          color: #fff;
          text-decoration: underline; }
  .main_stok .main_stok-item {
    height: 20px;
    max-width: 50px;
    width: 100%;
    background: #fff; }

.main {
  margin-top: 5px;
  min-height: 70vh; }

.filter-sets-widget .widget-header {
  margin: 0;
  padding: 5px 10px;
  background: #8fbf21;
  color: #fff;
  font-weight: bold !important; }

.categories-list.sidebar-widget .widget-header {
  margin: 0;
  padding: 5px 10px;
  background: #8fbf21;
  color: #fff;
  font-weight: bold !important;
  position: relative; }
  .categories-list.sidebar-widget .widget-header:after {
    content: "\f078";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    top: 15px;
    right: 20px; }

.categories-list.sidebar-widget.drop-down-catalog .widget-categories {
  border: 1px solid #404148; }

.categories-list.sidebar-widget .widget-categories {
  font-weight: normal;
  border: 1px solid #e1e1e1;
  background: #fff;
  border-top: none;
  list-style: none;
  padding-top: 0;
  font-size: 14px;
  text-transform: none;
  font-weight: normal; }
  .categories-list.sidebar-widget .widget-categories li {
    padding-left: 15px; }
  .categories-list.sidebar-widget .widget-categories a {
    color: #8fbf21;
    text-decoration: none;
    transition: all ease .45s;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    line-height: 2.4;
    font-size: 18px;
    font-weight: bold; }
    .categories-list.sidebar-widget .widget-categories a:hover {
      text-decoration: none;
      color: #404148; }
    .categories-list.sidebar-widget .widget-categories a:focus {
      text-decoration: none;
      color: #404148; }
    .categories-list.sidebar-widget .widget-categories a:active {
      text-decoration: none;
      color: #404148; }
  .categories-list.sidebar-widget .widget-categories ul {
    list-style: none;
    padding-top: 5px;
    font-size: 14px;
    text-transform: none;
    font-weight: normal;
    display: none; }
    .categories-list.sidebar-widget .widget-categories ul li {
      padding-left: 15px; }
  .categories-list.sidebar-widget .widget-categories .cat {
    width: 40px;
    display: inline-block;
    text-align: center;
    margin-right: 10px; }
  .categories-list.sidebar-widget .widget-categories img.catalog-img {
    display: none; }
  .categories-list.sidebar-widget .widget-categories img.catalog-icon {
    max-width: 40px;
    width: auto;
    max-height: 40px;
    vertical-align: middle;
    transition: all ease .3s; }
  .categories-list.sidebar-widget .widget-categories .lvl1 {
    position: relative; }
    .categories-list.sidebar-widget .widget-categories .lvl1 a {
      line-height: 2.4;
      padding: 1.5px; }
      .categories-list.sidebar-widget .widget-categories .lvl1 a img.catalog-icon {
        max-width: 40px;
        width: auto;
        vertical-align: middle; }
      .categories-list.sidebar-widget .widget-categories .lvl1 a img.catalog-img {
        display: none; }
    .categories-list.sidebar-widget .widget-categories .lvl1.active {
      background: #00964a; }
      .categories-list.sidebar-widget .widget-categories .lvl1.active a {
        color: #fff; }
      .categories-list.sidebar-widget .widget-categories .lvl1.active img {
        filter: brightness(0) invert(100); }
    .categories-list.sidebar-widget .widget-categories .lvl1:hover {
      background: #8fbf21; }
      .categories-list.sidebar-widget .widget-categories .lvl1:hover a {
        color: #fff; }
      .categories-list.sidebar-widget .widget-categories .lvl1:hover img {
        filter: brightness(0) invert(100); }
    .categories-list.sidebar-widget .widget-categories .lvl1:hover .lvl2 {
      display: block; }
    .categories-list.sidebar-widget .widget-categories .lvl1:last-child {
      margin-bottom: 0; }
  .categories-list.sidebar-widget .widget-categories .lvl2 {
    position: absolute;
    top: 0;
    left: 99%;
    z-index: 110;
    background: #8fbf21;
    padding: 10px 0;
    width: 330px;
    display: none;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.05), 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
    .categories-list.sidebar-widget .widget-categories .lvl2 > li {
      transition: all .4s ease; }
      .categories-list.sidebar-widget .widget-categories .lvl2 > li.my_hover {
        background: #dadada; }
        .categories-list.sidebar-widget .widget-categories .lvl2 > li.my_hover > a {
          color: #222; }
      .categories-list.sidebar-widget .widget-categories .lvl2 > li:hover {
        background: #f5b1d0; }
      .categories-list.sidebar-widget .widget-categories .lvl2 > li:hover .lvl3 {
        display: block; }
    .categories-list.sidebar-widget .widget-categories .lvl2 li {
      padding: 5px 10px; }
      .categories-list.sidebar-widget .widget-categories .lvl2 li img.catalog-icon, .categories-list.sidebar-widget .widget-categories .lvl2 li img.catalog-img {
        display: none; }
      .categories-list.sidebar-widget .widget-categories .lvl2 li a {
        font-size: 17px;
        color: #fff; }
        .categories-list.sidebar-widget .widget-categories .lvl2 li a img.catalog-icon, .categories-list.sidebar-widget .widget-categories .lvl2 li a img.catalog-img {
          display: none; }
    .categories-list.sidebar-widget .widget-categories .lvl2 .lvl3 {
      -webkit-box-shadow: 4px -1px 4px 1px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 4px -1px 4px 1px rgba(0, 0, 0, 0.15);
      box-shadow: 4px -1px 4px 1px rgba(0, 0, 0, 0.15);
      border-right: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1;
      padding: 0;
      min-width: 450px;
      position: absolute;
      top: 0;
      left: 99%;
      background: #fff;
      display: none; }
      .categories-list.sidebar-widget .widget-categories .lvl2 .lvl3 a {
        color: #222;
        font-size: 16px;
        text-align: center;
        transition: all .3s ease;
        line-height: 1.5; }
        .categories-list.sidebar-widget .widget-categories .lvl2 .lvl3 a:hover {
          color: #00964a; }
        .categories-list.sidebar-widget .widget-categories .lvl2 .lvl3 a img.catalog-img {
          display: none; }
        .categories-list.sidebar-widget .widget-categories .lvl2 .lvl3 a img.catalog-icon {
          display: block;
          margin: 0 auto;
          text-align: center;
          width: auto;
          height: 70px;
          max-width: 100%;
          filter: inherit; }
        .categories-list.sidebar-widget .widget-categories .lvl2 .lvl3 a .cat {
          display: block;
          width: 100%; }
      .categories-list.sidebar-widget .widget-categories .lvl2 .lvl3 li {
        margin-bottom: 10px;
        height: 120px;
        overflow: hidden; }
      .categories-list.sidebar-widget .widget-categories .lvl2 .lvl3:after {
        content: '';
        position: absolute;
        min-width: 450px;
        width: 100%;
        height: 20px;
        background: #00964a;
        left: 0;
        bottom: -20px;
        -webkit-box-shadow: 4px 1px 4px 1px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 4px 1px 4px 1px rgba(0, 0, 0, 0.15);
        box-shadow: 4px 1px 4px 1px rgba(0, 0, 0, 0.15);
        border-right: 1px solid #e1e1e1; }
    .categories-list.sidebar-widget .widget-categories .lvl2 .lvl3.active {
      display: block; }

.hidden {
  display: none; }

.categories-list.sidebar-widget .widget-header, .sidebar-widget .widget-header {
  margin: 0;
  padding: 12px 10px;
  background: #8fbf21;
  color: #fff;
  font-weight: normal !important;
  padding-bottom: 15px;
  text-transform: uppercase;
  margin-bottom: 0 !important; }

.categories-list.sidebar-widget .categories-list, .sidebar-widget .categories-list {
  list-style: none;
  padding-top: 5px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: normal; }
  .categories-list.sidebar-widget .categories-list li, .sidebar-widget .categories-list li {
    padding-left: 15px; }
  .categories-list.sidebar-widget .categories-list a, .sidebar-widget .categories-list a {
    color: #fff;
    text-decoration: none;
    transition: all ease .45s; }
    .categories-list.sidebar-widget .categories-list a:hover, .sidebar-widget .categories-list a:hover {
      text-decoration: none;
      color: #404148; }
    .categories-list.sidebar-widget .categories-list a:focus, .sidebar-widget .categories-list a:focus {
      text-decoration: none;
      color: #404148; }
    .categories-list.sidebar-widget .categories-list a:active, .sidebar-widget .categories-list a:active {
      text-decoration: none;
      color: #404148; }

.filters_title {
  margin: 0;
  padding: 5px 10px;
  background: #8fbf21;
  color: #fff;
  font-weight: bold !important; }

.left-filters {
  padding-right: 20px;
  padding-top: 100px; }
  .left-filters .filter-sets-widget {
    background: #eee; }
  .left-filters .sidebar-widget {
    border: none; }

.filters {
  padding: 20px 10px;
  -webkit-box-shadow: 1px 6px 24px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 1px 6px 24px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 1px 6px 24px 0px rgba(50, 50, 50, 0.75); }
  .filters .property-values a {
    color: #fff;
    text-decoration: none;
    transition: all ease .45s; }
    .filters .property-values a:hover {
      text-decoration: none;
      color: #404148; }
    .filters .property-values a:focus {
      text-decoration: none;
      color: #404148; }
    .filters .property-values a:active {
      text-decoration: none;
      color: #404148; }
  .filters .filter-property {
    padding: 5px 15px; }
    .filters .filter-property li {
      margin-bottom: 10px; }
    .filters .filter-property .property-name {
      padding-bottom: 5px;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: bold;
      color: #8fbf21;
      text-transform: uppercase;
      text-align: center; }
    .filters .filter-property input.filter-check {
      display: none; }
  .filters .filter-panel {
    padding: 10px; }
  .filters .btn-primary {
    border: 1px solid #8fbf21;
    border-radius: 3px;
    color: #8fbf21;
    background: transparent;
    padding: 5px 15px;
    transition: all .3s ease;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    display: block;
    margin: 0 auto 10px;
    display: none; }
    .filters .btn-primary:hover, .filters .btn-primary:focus, .filters .btn-primary:active {
      background: #8fbf21;
      color: #fff;
      border-color: #8fbf21; }

.drop-down-catalog {
  position: relative; }
  .drop-down-catalog.sidebar-widget {
    margin-bottom: 0;
    padding-bottom: 0; }
  .drop-down-catalog .widget-categories {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 100; }

.menu-back {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99; }

.home-tabs-container {
  margin-top: 15px; }

.main_page_filter .checkbox_img {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 1px solid #ccc;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle; }
  .main_page_filter .checkbox_img img {
    max-width: 39px;
    width: auto;
    max-height: 39px; }

.main_page_filter .property-values li .checkbox_back, .main_page_filter .property-values label .checkbox_back {
  margin-left: 10px;
  margin-right: 25px; }

.main_page_filter .property-values li a, .main_page_filter .property-values label a {
  display: inline-block;
  width: calc(100% - 55px); }

.property-values li, .property-values label {
  display: block;
  font-weight: normal; }
  .property-values li .checkbox_back, .property-values label .checkbox_back {
    display: block;
    cursor: pointer;
    float: left;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 50%; }
    .property-values li .checkbox_back.active_back, .property-values label .checkbox_back.active_back {
      background: url("/img/checkboxes/checkbox_active.png") no-repeat !important;
      background-size: contain !important; }
  .property-values li:hover .checkbox_back, .property-values label:hover .checkbox_back {
    background: url("/img/checkboxes/checkbox_hover.png") no-repeat !important;
    background-size: contain !important; }
  .property-values li a, .property-values label a {
    display: block; }
    .property-values li a span.kolvo, .property-values label a span.kolvo {
      float: right;
      font-weight: bold; }

.min-max {
  margin-bottom: 10px;
  text-align: center; }
  .min-max input {
    max-width: 85px;
    width: 100%;
    border: none;
    padding: 5px 0;
    font-weight: bold; }
  .min-max span.range-txt {
    color: #8fbf21;
    font-weight: bold; }

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  background: #fff;
  border: 2px solid #8fbf21;
  border-radius: 50%;
  outline: none; }

.ui-slider .ui-slider-range {
  background: #8fbf21; }

.ui-widget-content {
  border: none;
  margin-bottom: 10px; }

.btn-filter-show {
  display: none; }

.main_page_filter .filters .filter-property .property-name {
  font-size: 18px;
  text-transform: uppercase; }

.slider {
  width: 100%;
  height: 250px;
  background: #7c18ea; }

.main_features {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; }
  .main_features .main_features-item {
    height: 100px;
    width: 200px;
    background: #8fbf21; }

.tabs_wrap {
  margin-top: 20px; }
  .tabs_wrap .tabs a {
    font-size: 24px;
    width: 33.1%;
    text-align: center;
    padding: 10px 0;
    display: inline-block;
    background: transparent;
    color: #8fbf21;
    transition: all .3s ease;
    text-decoration: none;
    cursor: pointer; }
    .tabs_wrap .tabs a:hover {
      background: #8fbf21;
      color: #fff; }
  .tabs_wrap .tabs a.active-tab {
    background: #8fbf21;
    color: #fff; }
  .tabs_wrap .tabs_content {
    margin-top: 20px; }
    .tabs_wrap .tabs_content div {
      display: none; }
    .tabs_wrap .tabs_content div.active-tab {
      display: block; }

.advantages {
  margin-top: 20px;
  padding: 0 10px;
  clear: both; }
  .advantages .advantages_title {
    margin: 0;
    padding: 5px 10px;
    background: transparent;
    color: #8fbf21;
    font-weight: bold !important;
    text-align: center;
    font-size: 24px; }
  .advantages .advantages-items {
    margin-top: 20px; }
    .advantages .advantages-items .advantages-item {
      border-right: 1px solid #ccc; }
      .advantages .advantages-items .advantages-item:last-child {
        border: none; }
      .advantages .advantages-items .advantages-item i {
        background: #8fbf21;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        width: 45px;
        text-align: center;
        font-size: 18px;
        margin-right: 5px; }
      .advantages .advantages-items .advantages-item .item-description {
        display: inline-block;
        width: calc(100% - 60px);
        vertical-align: top; }
        .advantages .advantages-items .advantages-item .item-description p span {
          font-weight: bold;
          font-size: 16px;
          color: #8fbf21;
          text-transform: uppercase; }
      .advantages .advantages-items .advantages-item .advantage_icon {
        background: #8fbf21;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        width: 45px;
        text-align: center;
        font-size: 18px;
        margin-right: 5px;
        text-align: center;
        display: inline-block; }
        .advantages .advantages-items .advantages-item .advantage_icon img {
          width: 25px; }

.order_wrap {
  margin-top: 20px; }
  .order_wrap .order_title {
    margin: 0;
    padding: 5px 10px;
    background: transparent;
    color: #8fbf21;
    font-weight: bold !important;
    text-align: center;
    font-size: 24px; }
  .order_wrap .order-items {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; }
  .order_wrap .order-item {
    background: #8fbf21;
    width: 20px;
    height: 20px; }

.wrap {
  margin-top: 20px; }

.main_description p {
  font-size: 16px; }

.product-item .product-announce, .product-item .cta {
  position: initial; }

.nav-tabs {
  border: none;
  display: flex;
  justify-content: space-between; }
  .nav-tabs li {
    margin: 0;
    float: none;
    display: inline-block;
    width: 100%;
    text-align: center;
    transition: all .2s ease;
    border-bottom: 2px solid transparent;
    color: #8fbf21; }
    .nav-tabs li:hover {
      border-color: #8fbf21; }
      .nav-tabs li:hover a {
        color: #8fbf21; }
        .nav-tabs li:hover a:hover {
          color: #8fbf21; }
    .nav-tabs li a {
      border: none;
      font-size: 18px;
      color: #8fbf21; }
      .nav-tabs li a:hover, .nav-tabs li a:focus {
        border: none;
        background: none;
        color: #8fbf21; }
  .nav-tabs li.active {
    border-color: #8fbf21; }
    .nav-tabs li.active a {
      border: none;
      color: #8fbf21; }
      .nav-tabs li.active a:hover, .nav-tabs li.active a:focus {
        border: none;
        background: none;
        color: #8fbf21; }

#input-cost {
  padding: 20px 0; }

.product-item {
  margin-bottom: 20px;
  cursor: pointer; }
  .product-item a {
    color: #8fbf21;
    transition: color .4s ease; }
  .product-item .product-image {
    height: 250px; }
  .product-item .cta {
    text-align: right; }
    .product-item .cta .btn.btn-add-to-cart, .product-item .cta .btn-add-to-cart {
      border-color: #8fbf21;
      color: #8fbf21;
      background: transparent; }
      .product-item .cta .btn.btn-add-to-cart:focus, .product-item .cta .btn.btn-add-to-cart:active, .product-item .cta .btn-add-to-cart:focus, .product-item .cta .btn-add-to-cart:active {
        border-color: #8fbf21;
        color: #8fbf21;
        background: transparent; }
      .product-item .cta .btn.btn-add-to-cart:hover, .product-item .cta .btn-add-to-cart:hover {
        background: #8fbf21;
        color: #fff; }
  .product-item .product {
    font-size: 18px; }
    .product-item .product .product-title {
      min-height: 50px; }
    .product-item .product .product-price {
      text-align: right;
      font-weight: bold;
      color: #404148; }

.modular-items {
  margin-bottom: 20px; }
  .modular-items table {
    max-width: 990px; }
  .modular-items:after {
    content: '';
    display: block;
    width: 1px;
    height: 1px;
    visibility: hidden;
    clear: both; }

.modular_item_wrap {
  margin-bottom: 10px; }
  .modular_item_wrap .product-image img {
    width: auto; }
  .modular_item_wrap .item-name {
    font-size: 18px; }
  .modular_item_wrap .item-info {
    display: flex;
    margin-bottom: 10px; }
    .modular_item_wrap .item-info .item-choice {
      flex-grow: 1; }
    .modular_item_wrap .item-info .item-price {
      flex-grow: 1;
      text-align: right;
      font-size: 18px; }
  .modular_item_wrap .item-count .item-count-wrap {
    display: flex;
    justify-content: space-around; }
    .modular_item_wrap .item-count .item-count-wrap .item-count-btn {
      width: 35px;
      height: 35px;
      font-size: 18px;
      text-align: center;
      line-height: 35px;
      background: #8fbf21;
      color: #fff;
      cursor: pointer; }
    .modular_item_wrap .item-count .item-count-wrap .count-input {
      position: relative; }
      .modular_item_wrap .item-count .item-count-wrap .count-input input {
        max-width: 70px;
        height: 35px;
        font-size: 20px;
        text-align: center; }
      .modular_item_wrap .item-count .item-count-wrap .count-input:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: transparent; }
  .modular_item_wrap .item-btn {
    display: none; }
  .modular_item_wrap .price_wrap {
    font-size: 21px; }

.component-choose-button {
  max-width: 200px;
  width: 100%;
  background: #8fbf21;
  color: #fff;
  border-color: #8fbf21;
  margin: 0 auto;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 10px 0;
  cursor: pointer; }
  .component-choose-button:hover, .component-choose-button:active, .component-choose-button:focus {
    background: #006130;
    border-color: #006130; }

.product-params-wrap .properties-widget dl {
  display: flex; }

.product-params-wrap .properties-widget dd, .product-params-wrap .properties-widget dt {
  float: none; }

.product-params-wrap .properties-widget dt {
  max-width: 210px;
  width: 100%;
  font-size: 18px; }

.product-params-wrap .properties-widget .product-params {
  width: calc(100% - 125px); }
  .product-params-wrap .properties-widget .product-params dd {
    width: 100%;
    font-size: 18px; }

.btn-add-to-wishlist {
  display: none; }

.properties_wrap .property_item {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px; }
  .properties_wrap .property_item:nth-child(odd) {
    background: #f5f5f5; }
  .properties_wrap .property_item .property_name {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    display: inline-block; }
  .properties_wrap .property_item .property_value {
    display: inline-block;
    margin-left: 10px;
    font-size: 16px; }
  .properties_wrap .property_item.no_flex {
    display: block; }
    .properties_wrap .property_item.no_flex .property_name {
      display: block; }
    .properties_wrap .property_item.no_flex .property_value {
      display: block;
      padding: 10px 0; }
    .properties_wrap .property_item.no_flex .prod_color_wrap {
      text-align: center;
      display: inline-block;
      vertical-align: top; }

.variational_product {
  paddind: 0 10px; }
  .variational_product .variational_product_title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px; }
  .variational_product .variations_wrap {
    margin-bottom: 10px;
    padding: 0 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1; }
    .variational_product .variations_wrap h4 {
      margin-bottom: 5px; }
    .variational_product .variations_wrap .propLink {
      margin-right: 10px; }
      .variational_product .variations_wrap .propLink.blocks {
        display: block; }
      .variational_product .variations_wrap .propLink div {
        display: inline-block;
        margin-right: 10px; }

.concomitant_title {
  font-size: 24px;
  font-weight: bold; }

.concomitant_wrap {
  padding: 0 40px; }
  .concomitant_wrap .concomitant_item a div img {
    max-width: 200px;
    max-height: 200px;
    margin: 0 auto; }
  .concomitant_wrap .concomitant_item .related_img {
    width: 250px;
    height: 250px;
    margin: 0 auto;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important; }
  .concomitant_wrap .concomitant_item .related_name {
    font-size: 20px;
    text-align: center; }
  .concomitant_wrap .slick-prev:before, .concomitant_wrap .slick-next:before {
    color: #8fbf21; }

.related_bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0; }
  .related_bottom .btn.btn-add-to-cart {
    max-width: 200px;
    width: 100%;
    background: #8fbf21;
    color: #fff;
    border-color: #8fbf21;
    margin-bottom: 10px; }
    .related_bottom .btn.btn-add-to-cart:hover, .related_bottom .btn.btn-add-to-cart:active, .related_bottom .btn.btn-add-to-cart:focus {
      background: #006130;
      border-color: #006130; }
  .related_bottom .price {
    font-weight: bold;
    color: #404148; }

.low_padding {
  padding: 0 5px; }

.product-item {
  height: auto !important; }
  .product-item .product-image img {
    max-width: 100%;
    width: 100% !important; }
  .product-item .product {
    position: initial !important; }
  .product-item .product-name {
    width: 100% !important;
    float: none !important;
    display: block; }
  .product-item .product-announce {
    display: none; }
  .product-item .product-price {
    width: 100% !important; }
  .product-item .cta {
    position: initial !important;
    width: 100% !important; }

.block-product-list {
  clear: both; }
  .block-product-list .row {
    padding: 0 15px; }
  .block-product-list .col-md-4 {
    padding: 0 5px; }

.map iframe {
  width: 100%;
  height: 500px; }

.map_wrap > div {
  display: inline-block;
  vertical-align: middle;
  width: 49%; }

.map_description {
  vertical-align: middle; }
  .map_description .map_description_item {
    display: flex; }
    .map_description .map_description_item div {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 15px;
      margin-right: 10px;
      text-transform: uppercase; }
      .map_description .map_description_item div i {
        background: #8fbf21;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        width: 40px;
        text-align: center; }

.cart #cart-table input.quantity {
  border-radius: 0;
  border-left: 0;
  text-align: center; }

.cart td {
  text-align: center;
  vertical-align: middle !important; }

.cart .btn-primary, .cart .btn-compare {
  border-color: #8fbf21;
  color: #8fbf21; }
  .cart .btn-primary:hover, .cart .btn-primary:focus, .cart .btn-primary:visited, .cart .btn-primary:active, .cart .btn-compare:hover, .cart .btn-compare:focus, .cart .btn-compare:visited, .cart .btn-compare:active {
    background: #8fbf21;
    border-color: #8fbf21;
    color: #fff; }

.cart .label-info {
  background: #8fbf21;
  display: inline-block;
  padding: 5px 10px;
  font-size: 21px; }

.cart .cta {
  margin-bottom: 20px; }

.product-show .cta .btn.btn-add-to-cart {
  max-width: 300px;
  width: 100%;
  border-radius: 0;
  background: transparent;
  color: #8fbf21;
  border-color: #8fbf21;
  margin-bottom: 10px;
  transition: all linear .45s; }
  .product-show .cta .btn.btn-add-to-cart:active, .product-show .cta .btn.btn-add-to-cart:focus {
    background: #8fbf21;
    color: #fff;
    border-color: #8fbf21; }
  .product-show .cta .btn.btn-add-to-cart:hover {
    background: #8fbf21;
    color: #fff;
    border-color: #8fbf21;
    box-shadow: 0 0 35px 4px #8fbf21; }

.price-name .price {
  color: #00964a;
  margin-top: 20px; }

#footer {
  display: none;
  padding-left: 15px;
  padding-right: 15px;
  background: #dadada;
  color: #222; }
  #footer .socials img {
    width: 40px; }
  #footer .footer {
    padding-top: 30px;
    padding-bottom: 30px; }
    #footer .footer .foot-logo .logo {
      margin: 0; }
    #footer .footer .foot-director {
      border: 1px solid #8fbf21;
      border-radius: 3px;
      color: #8fbf21;
      background: transparent;
      padding: 5px 15px;
      transition: all .3s ease;
      text-decoration: none;
      display: inline-block;
      text-align: center;
      cursor: pointer; }
      #footer .footer .foot-director:hover, #footer .footer .foot-director:focus, #footer .footer .foot-director:active {
        background: #8fbf21;
        color: #fff;
        border-color: #8fbf21; }
    #footer .footer .foot-menu-title {
      margin: 0;
      padding: 5px 10px;
      background: transparent;
      color: #8fbf21;
      font-weight: bold !important;
      font-size: 16px; }
    #footer .footer .navigation-widget {
      list-style: none;
      padding-top: 5px;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: normal;
      text-transform: none;
      font-size: 14px;
      font-weight: normal; }
      #footer .footer .navigation-widget li {
        padding-left: 15px; }
      #footer .footer .navigation-widget a {
        color: #000;
        text-decoration: none;
        transition: all ease .45s; }
        #footer .footer .navigation-widget a:hover {
          text-decoration: none;
          color: #404148; }
        #footer .footer .navigation-widget a:focus {
          text-decoration: none;
          color: #404148; }
        #footer .footer .navigation-widget a:active {
          text-decoration: none;
          color: #404148; }
        #footer .footer .navigation-widget a:hover {
          text-decoration: underline; }
    #footer .footer .footer-contacts i {
      background: #8fbf21;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
      width: 40px;
      text-align: center; }
    #footer .footer .footer-contacts > div {
      display: inline-block;
      vertical-align: top; }
    #footer .footer .footer-contacts p {
      color: #8fbf21; }
      #footer .footer .footer-contacts p a {
        color: #fff;
        text-decoration: none;
        transition: all ease .45s; }
        #footer .footer .footer-contacts p a:hover {
          text-decoration: none;
          color: #404148; }
        #footer .footer .footer-contacts p a:focus {
          text-decoration: none;
          color: #404148; }
        #footer .footer .footer-contacts p a:active {
          text-decoration: none;
          color: #404148; }
    #footer .footer .footer-contacts .foot-messangers img {
      width: 20px; }
    #footer .footer .footer-contacts .foot-address {
      width: calc(100% - 45px); }

#new_footer {
  color: #fff;
  background: #626262; }
  #new_footer .top-footer {
    padding: 25px 0; }
    #new_footer .top-footer ul {
      max-width: 1400px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      list-style: none; }
      #new_footer .top-footer ul li {
        margin-right: 10px; }
  #new_footer a {
    color: #fff;
    font-size: 16px; }

.content-part .product-show .product-images .first-image {
  display: block;
  height: 555px; }
  .content-part .product-show .product-images .first-image img {
    width: 100%;
    max-height: 555px; }

.other-images .other_img {
  height: 100%;
  background-size: 100% 100% !important; }

.selects .select_item {
  display: inline-block;
  margin: 1px;
  padding: 5px 1px 0;
  cursor: pointer; }
  .selects .select_item.selected {
    border: 1px solid #c7c7c7; }
  .selects .select_item:hover {
    border: 1px solid #c7c7c7; }

.propLink img {
  width: 50px;
  height: 50px;
  border: 1px solid #e1e1e1; }

.review {
  margin-left: 0;
  margin-right: 0; }
  .review .label-default {
    background-color: #8fbf21; }

.widget-reviews .summary {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e1e1e1; }

.right_info_wrap .info_title {
  margin: 0;
  padding: 5px 10px;
  background: #f5f5f5;
  border-radius: 10px;
  color: #8fbf21;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold; }

.right_info_wrap .info_description {
  padding: 5px 15px; }
  .right_info_wrap .info_description.delivery_info {
    padding: 5px 30px; }
  .right_info_wrap .info_description p {
    color: #8fbf21;
    font-size: 14px;
    margin: 0;
    position: relative; }

.right_info_wrap .delivery_info p:before {
  position: absolute;
  content: '';
  width: 15px;
  height: 15px;
  background-size: contain;
  left: -20px;
  top: 3px; }

.right_info_wrap p.nposhta:before {
  background: url("/img/icons/nposhta.png") no-repeat;
  background-size: contain; }

.right_info_wrap p.uposhta:before {
  background: url("/img/icons/point.png") no-repeat;
  background-size: contain; }

.right_info_wrap p.delivery_term:before {
  background: url("/img/icons/truck.png") no-repeat;
  background-size: contain; }

.product-show .cta {
  margin-bottom: 20px; }

.product-params-wrap {
  padding-top: 70px; }

.product-item {
  position: relative; }

.cta .added_message {
  padding: 10px 5px;
  border: 2px solid #8fbf21;
  color: #222;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  max-width: 250px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  display: none; }

.review-author {
  background: #a5a5a5;
  padding: 5px;
  margin-bottom: 0 !important;
  border-radius: 10px;
  color: #8fbf21; }
  .review-author i {
    color: #404148; }

.review-date_submitted {
  padding: 5px;
  border-radius: 10px; }

.review_col {
  background: #f5f5f5; }

.user_col, .review_col {
  padding: 15px 10px; }

.review_col {
  border-radius: 10px; }

#review-form h2 small {
  display: block; }

.prod_color_wrap {
  position: relative;
  width: 80px;
  text-align: center;
  display: inline-block;
  vertical-align: top; }
  .prod_color_wrap .zoom_color {
    display: none;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    position: absolute;
    border-radius: 10px;
    z-index: 10;
    top: -130px;
    left: -20px;
    background: #f5f5f5; }
    .prod_color_wrap .zoom_color img {
      width: 100px;
      height: 100px; }
  .prod_color_wrap:hover .zoom_color {
    display: block; }
  .prod_color_wrap .prod_color_title {
    margin: 0;
    line-height: 1;
    padding: 5px 0; }

.custom_image_widget {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center; }
  .custom_image_widget div {
    display: inline-block;
    max-width: 336px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px; }
    .custom_image_widget div a {
      display: block; }
    .custom_image_widget div img {
      max-height: 255px;
      width: auto;
      max-width: 100%; }

.product-list-item-img {
  display: inline-block;
  width: 30%; }
  .product-list-item-img img {
    max-width: 100%; }

.product-list-item-right {
  display: inline-block;
  vertical-align: middle;
  width: 69%; }

.product-list-item-link {
  display: inline-block;
  width: 49%;
  text-align: center; }

.product-list-item-info {
  display: inline-block;
  width: 49%;
  text-align: right; }
  .product-list-item-info h3 {
    margin: 0; }

.product-list-item .btn-small {
  display: none; }

.control-group {
  display: inline-block;
  margin-right: 10px; }

#blockView .row .col-md-6 {
  padding-top: 10px;
  padding-bottom: 10px; }
  #blockView .row .col-md-6:hover {
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.75); }

img.prod_color {
  width: 40px;
  height: 40px; }

.user_preferences {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; }
  .user_preferences .pull-right {
    display: none; }

.mebli_modals .form_title {
  font-size: 25px;
  text-align: center;
  margin-bottom: 10px;
  color: #8fbf21;
  font-weight: bold; }

.mebli_modals .form_description {
  text-align: center; }

.mebli_modals .form_content {
  padding: 20px 40px; }

.mebli_modals label {
  display: block;
  width: 420px;
  margin: 0 auto; }

.mebli_modals .inputs {
  display: block;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 15px;
  color: #8fbf21;
  border: 1px solid #c7c7c7;
  border-radius: 3px; }

.mebli_modals .submit_form {
  border: 1px solid #8fbf21;
  border-radius: 3px;
  color: #8fbf21;
  background: transparent;
  padding: 5px 15px;
  transition: all .3s ease;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  display: block;
  width: 420px;
  margin: 10px auto 0;
  padding: 10px 0; }
  .mebli_modals .submit_form:hover, .mebli_modals .submit_form:focus, .mebli_modals .submit_form:active {
    background: #8fbf21;
    color: #fff;
    border-color: #8fbf21; }

.mebli_modals .form_content textarea {
  width: 100%;
  height: 120px; }

.properties_title_wrap {
  background: #dadada;
  padding: 10px;
  font-size: 18px; }

.properties_wrap_tab .property_item {
  padding-left: 10px;
  padding-right: 10px; }
  .properties_wrap_tab .property_item .property_name {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #8fbf21;
    font-size: 20px; }
  .properties_wrap_tab .property_item .property_value {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #f5f5f5;
    border-radius: 0 0 5px 5px;
    font-size: 16px;
    border-radius: 0; }

.prop_after {
  position: relative; }

.my_map {
  width: 100%;
  height: 450px; }

.cart_title {
  text-align: center; }

#shop-stage h2 {
  text-align: center; }

#shop-stage .stage_buttons .btn.btn-primary {
  background: #8fbf21;
  transition: all ease .3s;
  border-color: #8fbf21;
  color: #fff; }
  #shop-stage .stage_buttons .btn.btn-primary:hover {
    background: #006130;
    border-color: #006130;
    color: #fff; }

#shop-stage .stage_buttons .list-stage-buttons li {
  text-align: center; }

.success_btns .btn-info {
  background: #8fbf21;
  transition: all ease .3s;
  border-color: #8fbf21;
  color: #fff; }
  .success_btns .btn-info:hover {
    background: #006130;
    border-color: #006130;
    color: #fff; }

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
  width: 100%; }

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  font-size: 60px;
  width: 50px;
  height: 50px; }

.owl-carousel .owl-nav button span {
  display: block; }

.owl-carousel .owl-nav button:focus {
  outline: none; }

.product-show .product_main_info {
  padding: 0 15px; }
  .product-show .product_main_info .product_title {
    color: #8fbf21;
    line-height: 1;
    font-size: 35px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center; }
  .product-show .product_main_info .flex_info {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold; }
  .product-show .product_main_info .product_main_price_block {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    margin-bottom: 20px; }
    .product-show .product_main_info .product_main_price_block .price-name .price {
      color: #bd0031;
      font-size: 29px;
      font-weight: bold; }
  .product-show .product_main_info .product_cart_wrapper {
    position: relative; }
    .product-show .product_main_info .product_cart_wrapper .added_message {
      padding: 20px 5px;
      border: 2px solid #ff6eb0;
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      max-width: 300px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #ff6eb0;
      display: none;
      color: #fff;
      -webkit-box-shadow: 0 0 35px 4px #ff6eb0;
      box-shadow: 0 0 35px 4px #ff6eb0; }
  .product-show .product_main_info .cta {
    text-align: left; }

.product-show .tabs_wrap {
  word-break: break-word; }

.product-show .counter_wrap {
  display: flex;
  justify-content: space-between; }
  .product-show .counter_wrap .counter_btn {
    width: 40px;
    height: 40px;
    line-height: 37px;
    font-size: 18px;
    color: #8fbf21;
    border: 3px solid #8fbf21;
    transition: all ease .45s;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    margin-right: 10px; }
    .product-show .counter_wrap .counter_btn:hover {
      color: #fff;
      background: #8fbf21; }
  .product-show .counter_wrap .counter_input {
    margin-right: 10px; }
    .product-show .counter_wrap .counter_input input {
      width: 80px;
      height: 40px;
      text-align: center;
      font-size: 20px;
      font-weight: bold; }
    .product-show .counter_wrap .counter_input input[type=number]::-webkit-inner-spin-button,
    .product-show .counter_wrap .counter_input input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }

.product-show .optional_information_wrap {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  background: #f5f5f5; }
  .product-show .optional_information_wrap .optional_information_item {
    padding: 0 40px;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    margin-right: 20px; }
    .product-show .optional_information_wrap .optional_information_item:before {
      content: '';
      width: 30px;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0; }
    .product-show .optional_information_wrap .optional_information_item.truck:before {
      background: url("/img/product/delivery-truck.png") no-repeat;
      background-size: 100% 100%; }
    .product-show .optional_information_wrap .optional_information_item.card:before {
      background: url("/img/product/credit-cards-payment.png") no-repeat;
      background-size: 100% 100%; }
    .product-show .optional_information_wrap .optional_information_item .title {
      font-size: 16px;
      font-weight: bold;
      color: #8fbf21;
      margin-bottom: 10px; }

.tracker {
  background: transparent !important; }
