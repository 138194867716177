@import 'partials/partials';
@import 'partials/mixins';

@font-face{
    font-family:"Evolventa";
    src:url('/fonts/evolventa.ttf');
}


.no_padding{
    padding-left: 0;
    padding-right: 0;

}
.sticky{
    position: fixed;
    z-index: 10;
    top:70px;
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.75);

    &.sticked{
        position: absolute;
    }
}

.content-part{
    &.no_padding{
        padding:0;
    }
}

.flow{
    overflow:hidden !important;
}

.right_product_wrap{
    position: relative;
    .right_product_block{
        //here
        padding: 20px 15px;
        //max-width: 330px;
        //width:100%;
        background: $on_primary_color;


        .cta{
            position: relative;

            .added_message{
                padding: 20px 5px;
                bottom:30px;
                left: 0;
                max-width: auto;
            }
        }
    }
}

.wrapper{
    max-width: 1350px;
    margin: 0 auto;

    &.top-wrapper{
        max-width:1440px;
    }

    .slick-prev{
        left:-10px;
        z-index: 100;
    }
    .slick-next{
        right:-10px;
        z-index: 10;
    }
}
body{
    //padding-top: 10px;
    font-family:'Evolventa' ,Arial,'IBM Plex Sans Condensed', sans-serif;
    min-height: 900px;
    a{
        //color: $red_color;
        color:$primary_color;
        &:hover,&:active,&:focus{
            color:$active_link;
            text-decoration: none;
        }

    }
    
}
body, .content, #content{
    color: $default_color;
}

//header start
.header-container-top{
    background:$primary_color;
    border-bottom:2px solid $secondary_color;
    position: relative;
    font-weight: bold;





}
.header-bottom{
    background:$grey_background;
}
.header-menu-top{
    display:flex;
    justify-content: space-between;
    align-items: center;

    .timetable{
        color:$on_primary_color;
    }
    a{
        @include links();
    }
    ul{
        display: inline-block;
        //border: 1px solid $grey_border;
        font-size: 14px;
        @include inline-list;
        li{
            //border-right:1px solid $grey_border;
        }
    }
}
.header-menu-top-right{
    background:$secondary_color;
    text-align: center;
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    height:30px;
    align-items: center;
    a.login_btn{
        @include inline_buttons($init_color:$default_color,$color:$login_link_color);
        flex-grow: 1;
    }
    a.register_btn{
        @include inline_buttons($init_color:$default_color,$color:$register_link_color);
        flex-grow: 1;
    }
    
}
.head_menu{
    //padding: 30px 0 0;
}

a.logo{

    display: block;
    margin: 0 auto;
    padding-top: 10px;
    img{
//        width:$logo_size;
        width: 100%;
    }
}
.search_wrap{
    padding: 30px 0;
    p{
        position: relative;
        input{
            padding: 5px 30px;
            @include rounded-border;
            border:1px solid #ccc;
        }
         &:before{
                @include fawesome-before($content:'\f002',$left:10px,$top:5px,$color:#ccc);

            }
    }
}
.timetable{
    //font-size:12px;
    font-size: 14px;
    //font-weight: bold;
    b,i{
        color:$primary_color;
    }
}

.head_phones{
    padding-top: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .head_phone{
        font-size: 20px;
        color:$on_primary_color;
        padding-bottom: 5px;
        line-height: 1;
        margin: 0;
        position: relative;

        &.lifecell{
            &:after{
                content:'';
                position:absolute;
                width:20px;
                height:20px;
                background:url('/img/lifecell.png')no-repeat;
                background-size: cover ;
                left: -25px;

            }
        }
        &.kyivstar{
            &:after{
                content:'';
                position:absolute;
                width:20px;
                height:20px;
                background:url('/img/kyivstar.png')no-repeat;
                background-size: cover ;
                left: -25px;
            }
        }
        &.vodafone{
            &:after{
                content:'';
                position:absolute;
                width:20px;
                height:20px;
                background:url('/img/vodafone.png')no-repeat;
                background-size: cover ;
                left: -25px;
            }
        }
    }
}
.head_address{
    padding-top: 20px;

    p{
        color:$on_primary_color;
        font-size: 20px;
    }
}

.head_cart {
    padding-top: 25px;
    padding-bottom: 10px;
    color:$text_color;
    img{
        width:45px;
        filter: grayscale(100%);
        transition: all ease .3s;
    }
    div{
        display: inline-block;
    }
    i{
        font-size: 35px;
        transition: color .5s ease;
    }
    p{
        display: inline-block;
        vertical-align: middle;
        font: {
            size: 16px;
            weight: bold;
        }
    }
    a{
        color:$text_color;
        &:hover{
            color:$text_color;
            text-decoration: none;
            img{
                filter: grayscale(0%);
            }
          i{
            color:$primary_color;
          }
        }
    }
    .badge{
        vertical-align: bottom;
        background:$secondary_color;
        color:$text_color;
        position: relative;
        left: -20px;
    }
}
.head_callback{
    margin-top: 5px;
    a{
        @include buttons;
    }
}
#search-form{
    .control-label{display:none;}
    .tt-menu{
        padding: 10px 5px;
    }
}
#search-form div > span {
    float:none!important;
    border:none;
    background: transparent;
    color:$on_primary_color;
    font-size: 30px;

    input{
        width: 100%;
        height: 44px;
        &.loading{
            background:$on_primary_color !important;
        }
    }
}
.tt-menu .tt-suggestion{
    word-wrap:break-word;
    white-space: normal !important;
    
}


.input-group .twitter-typeahead{
    //float: none !important;
    input{
        //width:100% !important;
    }
}
.head_right{
    text-align: center;
}

.head_socials{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    a{
        width:75px;
        height:70px;
        margin-right:15px;
        border:3px solid $on_primary_color;
        border-radius: 10px;
        position: relative;
        cursor: pointer;
        transition: all 0.5s linear;
        box-shadow: 1px 5px 20px -5px #4c595f;

        i{
            position: absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            font-size: 30px;
            transition:all 0.5s linear;
            color:$primary_color;
        }

        &:hover{
            background:$primary_color;
            border:3px solid $primary_color;
            box-shadow: 0 0 35px 4px $primary_color;
            i{
                color:$on_primary_color;
            }
        }

    }
}
.head_search_wrap{
    padding-top:30px;
    .help-block{
        position: absolute;
        top:0;
    }
}

.breadcrumb{
    padding:13px 15px;
    margin:0;
    background:#e8e8e8;
    li{
        line-height: 1;
        a{
            color:$default_color;
            &:hover{
                text-decoration: underline;
            }
        }
        &.active{
            //color:$primary_color;
            font-size: 16px;
            font-weight: bold;
        }
    }
}
.btn-wishlist{
    display:none;
}

.left-sidebar{
    padding-right:0px;
    padding-left: 0;
}
.search-col{
    padding-left: 0;
    padding-right: 0;
}

.search-wrap{
    background:$primary_color;
    padding:5px 0;
    .help-block{
        position: absolute;
        margin-top: 0;
        margin-bottom: 0;
        z-index: 10;
        background: $primary_color;
        padding: 0 5px;
    }

    input.tt-input{
        border-radius:0 !important;

        &.loading{
            background:$on_primary_color !important;
        }
    }
}
.content-part{
    //padding-left: 5px;
    padding: 0 15px;
}

.dropdown .dropdown-menu{
    left:-90px;
}


.sliding_block_container{
    background: $primary_color;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    display: none;
    box-shadow: 0 0 35px 4px $add_message_color;
}
.sliding_block{
    .no_padding{
        padding: 0;
    }

    .input-group{

        input{
            border-radius: 0;
            height:40px;
        }
    }
}



//header end


.titler{
    font-size: 33px;
    font-weight: bold;
    text-transform: uppercase;
    color:$green_text;
    text-align: center;
    margin-bottom: 20px;
}

.products_grids{
    .grid{
        .col-md-4{
            padding: 0 7px;
        }
    }
}

.grid{
    margin: 20px 0;
    position: relative;
    
    &:after{
        content: '.';
        visibility: hidden;
        width:1px;
        height:1px;
        clear: both;
    }

}

.categories-list.sidebar-widget{
    border-bottom: 0;
}


.block-product-list{
    .main_item{

        .main_item_img{
            height: 297px;
        }
    }
}

.main_item {
    //background: $lightyellow_color;
    background: $filter_background;
    margin-bottom: 15px;
    transition:all linear .45s;
    .main_item_img {
        height: 345px;

        div{
            display: block;
            height:100%;
            overflow:hidden
        }

        img {
            max-width: 100%;
            width:100%;
            max-height: 100%;
            height:auto;
            display: block;
            transition: all linear .45s;
            margin: 0 auto;
        }
    }

    .main_item_title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: $text_color;
        padding: 5px 0;
        &:hover {
            color: $text_color;
        }
    }
    .main_item_info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 10px;
        position: relative;
        .main_item_price {
            font-size: 25px;
            font-weight: bold;
            color:$red_price;
        }

        .main_item_add {
            max-width: 175px;
            padding: 10px;
            display: block;
            width: 100%;
            text-align: center;
            color: $primary_color;
            border: 2px solid $primary_color;
            font-weight: bold;
            transition: all linear .45s;
            cursor: pointer;
            &:hover {
                background: $primary_color;
                color: $on_primary_color;
                box-shadow: 0 0 35px 4px $primary_color;
            }

        }
    }

    &:hover{
        background: darken($filter_background,4);

        .main_item_img{
            img{
                transform:scale(1.1);
            }
        }
    }

    .added_message{
        padding: 16px 5px;
        border:2px solid $add_message_color;
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        max-width:200px;
        width:100%;
        position: absolute;
        bottom:20px;
        right:10px;
        background: $add_message_color;
        display:none;
        color: $on_primary_color;
        box-shadow: 0 0 35px 4px $add_message_color;
    }

}
.main_content_inner{
    background:url('/img/main_content.png')no-repeat;
    background-size:cover;
    text-align: justify;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 40px;
    position: relative;

    .wrapper{
        //height:170px;
        overflow: hidden;
    }

    .more_btn{
        text-align: center;

        span{
            color:$primary_color;
            font-size: 60px;
            transform:rotate(180deg);
            display: inline-block;
            cursor: pointer;
            padding: 20px 40px;
            background:rgba(255,255,255,0.3);
            border-radius: 50%;
            transition:all linear .45s;

            &.rotate{
                transform:rotate(0deg);
            }

            &:hover{
                background:rgba(255,255,255,0.7)
            }
        }
    }
}





.main_stok{
    //display: flex;
    //justify-content: space-between;
    padding: 20px 40px;
    background: $primary_color;
    ul{
        list-style:none;
        margin: 0;
        //display: flex;
        //justify-content: space-between;
        li{
            display: inline-block;
            margin-right: 15px;
            a{
                color:$on_primary_color;
                &:hover{
                    color: $on_primary_color;
                    text-decoration: underline;
                }
            }
        }
    }

    .main_stok-item{
        height: 20px;
        max-width: 50px;
        width: 100%;
        background: #fff;
        
    }
}

.main{
    margin-top: 5px;
    min-height: 70vh;
}
.filter-sets-widget .widget-header{
    @include titler();

}
//left sidebar start
.filters{
    //background:$lightyellow_color;
}
.categories-list.sidebar-widget{
    .widget-header{
        @include titler();
        position: relative;
        &:after{
            content: "\f078";
            font:normal normal normal 14px/1 FontAwesome;
            position: absolute;
            top:15px;
            right:20px;
        }
    }
    &.drop-down-catalog .widget-categories{
        border:1px solid $default_color;
    }
    .widget-categories{
        //background:$lightyellow_color;
        font-weight:normal;
        border:1px solid $grey_border;
        background:$on_primary_color;
        border-top: none;

        @include lists(14px,$text_transform:none,$pt:0);
        a{
            @include links($primary_color);
            -ms-word-wrap: break-word;
            word-wrap: break-word;
            line-height:2.4;
            font-size:$menu_font_size;
            font-weight: bold;
        }
        ul{
            @include lists(14px,$text_transform:none);
            display:none;
        }
        .cat{
            width:40px;
            display: inline-block;
            text-align: center;
          margin-right: 10px;
        }
        img.catalog-img{
            display:none;
        }
        img.catalog-icon{
            max-width:40px;
            width:auto;
            max-height:40px;
            vertical-align: middle;
            transition: all ease .3s;
        }

        //Градация уровней

        .lvl1 {
            position: relative;
            //transition: all .4s ease;
            a{
                //transition: all .4s ease;
                line-height:2.4;
                padding: 1.5px;
                img.catalog-icon{
                    max-width:40px;
                    width:auto;
                    vertical-align: middle;
                }
                img.catalog-img{
                    display:none;
                }
            }
            &.active{
                background: $active_link;
                a{
                    color:$on_primary_color;
                }
                img{
                    filter:brightness(0) invert(100);
                }
            }
            &:hover{
                background: $primary_color;
                a{
                    color:$on_primary_color;
                }
                img{
                    filter:brightness(0) invert(100);
                }
            }
            &:first-child{

            }
            &:hover .lvl2{
                display: block;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
        .lvl2{
            position: absolute;
            top:0;
            //left:300px;
            left:99%;
            z-index: 110;
            background:$primary_color;
            padding:10px 0;
            width:330px;
            display: none;
            box-shadow: 0 0 2px 0 rgba(0,0,0,.05), 0 1px 4px 0 rgba(0,0,0,.15);

            >li{
                transition:all .4s ease;
                &.my_hover{
                    background:$primary_background;
                    >a{
                        color:#222;
                    }
                }

                &:hover{
                    //background:$active_link;
                    background: $secondary_color;
                    >a{

                    }
                }
                &:hover .lvl3{
                    display: block;
                }
            }
            li{
                padding: 5px 10px;

                img.catalog-icon, img.catalog-img{
                    display: none;

                }
                a{
                    font-size:$menu_font_size - 1;
                    color:$on_primary_color;
                    img.catalog-icon, img.catalog-img{
                        display:none;
                    }
                }
            }
            .lvl3{
                //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                //-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                //-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                -webkit-box-shadow: 4px -1px 4px 1px rgba(0,0,0,0.15);
                -moz-box-shadow: 4px -1px 4px 1px rgba(0,0,0,0.15);
                box-shadow: 4px -1px 4px 1px rgba(0,0,0,0.15);
                border-right:1px solid $grey_border;
                border-top:1px solid $grey_border;
                padding: 0;
                min-width:450px;
                position: absolute;
                top:0;
                //right:-300px;
                //left: 330px;
                left:99%;
                background:$secondary_background;
                a{
                    color:$text_color;
                    font-size:$menu_font_size - 2;
                    text-align:center;
                    transition: all .3s ease;
                    line-height: 1.5;
                    &:hover{
                        color:$active_link;
                    }
                    img.catalog-img{display: none;}
                    img.catalog-icon{
                        display: block;
                        margin:0 auto;
                        text-align: center;
                        width:auto;
                        height:70px;
                      max-width: 100%;
                      filter:inherit;
                    }
                  .cat{
                    display: block;
                    width:100%;
                  }
                }
                display: none;
                li{
                    margin-bottom:10px;
                    height:120px;
                    overflow:hidden;
                }
                &:after{
                    content: '';
                    position: absolute;
                    min-width:450px;
                    width:100%;
                    height: 20px;
                    background:$active_link;
                    left: 0;
                    bottom: -20px;
                    //-webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
                    //-moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
                    //box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
                    -webkit-box-shadow: 4px 1px 4px 1px rgba(0,0,0,0.15);
                    -moz-box-shadow: 4px 1px 4px 1px rgba(0,0,0,0.15);
                    box-shadow: 4px 1px 4px 1px rgba(0,0,0,0.15);
                    border-right:1px solid $grey_border;
                }
            }
            .lvl3.active{
                display: block;
            }
        }
        //Градация уровней end

    }
}
.hidden{
    display: none;
}
.categories-list.sidebar-widget, .sidebar-widget{
    
    .widget-header{
        @include titler($paddingY:12px,$weight:normal);
        padding-bottom: 15px;
        text-transform: uppercase;
        margin-bottom:0 !important;
    }
    .categories-list{
        @include lists;
        a{
            @include links;
        }
    }
}

.filters_title{
    @include titler;
}


// filters start

.left-filters{
    padding-right: 20px;
    padding-top: 100px;

    .filter-sets-widget{
        background: $filter_background;
    }


    .sidebar-widget{
        border:none;
    }
}




.filters {
    padding: 20px 10px;

    -webkit-box-shadow: 1px 6px 24px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    1px 6px 24px 0px rgba(50, 50, 50, 0.75);
    box-shadow:         1px 6px 24px 0px rgba(50, 50, 50, 0.75);
    .property-values{
        //@include lists;
        a{
            @include links
        }

    }

    .filter-property{
        padding: 5px 15px;

        li{
            margin-bottom: 10px;
        }

        .property-name{
            padding-bottom: 5px;
            margin-bottom: 5px;
            font-size: 16px;
            //border-bottom:1px solid $grey_border;
            font-weight:bold;
            color:$primary_color;
            text-transform: uppercase;
            text-align: center;

        }
        input.filter-check{
            display: none;
        }

    }
    .filter-panel{
        padding: 10px;
    }
    .btn-primary{
        @include buttons;
        display: block;
        margin:0 auto 10px;
        display: none;
    }
}

// filters end

// dropdown start
.drop-down-catalog{
    position: relative;
    &.sidebar-widget{
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .widget-categories{
        display:none;
        position: absolute;
        width:100%;
        z-index: 100;
    }

}
.menu-back{
    display: none;
    position: fixed;
    height: 100vh;
    width:100%;
    top:0;
    left: 0;
    right: 0;
    //background: rgba(255,255,255,0.7);
    background: rgba(0,0,0,0.7);
    z-index: 99;
}
// dropdown end



.home-tabs-container{
    margin-top: 15px;
}
.main_page_filter{
    .checkbox_img{
        width:40px;
        height:40px;
        display: inline-block;
        border:1px solid #ccc;
        margin-right:10px;
        text-align: center;
        vertical-align: middle;
        img{
            max-width: 39px;
            width:auto;
            max-height: 39px;
        }
    }
    .property-values li .checkbox_back, .property-values label .checkbox_back{
        margin-left: 10px;
        margin-right: 25px;
    }
    .property-values li a, .property-values label a{
        display: inline-block;
        width:calc(100% - 55px);
    }
}

.property-values li, .property-values label{
    display: block;
    font-weight:normal;

    .checkbox_back{
        //display: inline-block;
        //vertical-align: middle;
        display: block;
        cursor:pointer;
        float: left;
        //margin-right: 10px;
        margin-right: 10px;
        width:20px;
        height:20px;
        background:#fff;
        border:1px solid #ccc;
        border-radius:50%;
        &.active_back{
            background:url('/img/checkboxes/checkbox_active.png') no-repeat !important;
            background-size:contain !important;
        }
    }
    &:hover .checkbox_back{
        background:url('/img/checkboxes/checkbox_hover.png')no-repeat !important;
        background-size:contain !important;
    }
    a{
        display: block;
        span.kolvo{
            float: right;
            font-weight: bold;
        }
    }
}

.min-max{
    margin-bottom:10px;
    text-align: center;
    input{
        max-width:85px;
        width:100%;
        border:none;
        padding: 5px 0;
        font-weight: bold;
    }

    span.range-txt{
        color:$primary_color;
        font-weight: bold;
    }
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default{
    background:#fff;
    border:2px solid $primary_color;
    border-radius:50%;
    outline:none;
}
.ui-slider .ui-slider-range{
    background:$primary_color;
}
.ui-widget-content{
    border:none;
    margin-bottom:10px;
}
.btn-filter-show{
    display:none;
}

//left sidebar end








//main-part start
.main_page_filter{
    .filters .filter-property .property-name{
        font-size: 18px;
        text-transform: uppercase;
    }
}
.slider{
    width: 100%;
    height: 250px;
    background: #7c18ea;
    
}
.main_features{
    display: flex;
    justify-content: space-between;
    margin-top: $m_top;
    
    .main_features-item{
        height: 100px;
        width: 200px;
        background: $primary_color;
    }
}

.tabs_wrap{   
    margin-top: $m_top;
    .tabs{
        
        a{
            font-size: 24px;
            width: 33.1%;
            text-align: center;
            padding: 10px 0;
            display: inline-block;
            background: transparent;
            color: $primary_color;
            transition: all .3s ease;
            text-decoration: none;
            cursor: pointer;
            &:hover{
                @include active_link;
            }
        }
        a.active-tab{
            @include active_link;
        }
        
        
    } 
    .tabs_content{
        margin-top: $m_top;
        div{
            display: none;
        }
        div.active-tab{
            display: block;
        }
    }
}

.advantages{
    margin-top: $m_top;
    padding: 0 10px;
    clear: both;
    
    .advantages_title{
        @include titler(transparent,$primary_color);
        text-align: center;
        font-size: 24px;
    }
    
    .advantages-items{
        //@include flexer;
        margin-top: $m_top;
        
        .advantages-item{
//            background: $primary_color;
//            width:20px;
//            height: 20px;
            border-right:1px solid #ccc;
            &:last-child{
                border:none;
            }
            i{
                @include box-icon(45px);
                font-size: 18px;
                margin-right: 5px;
            }
            .item-description{
                display: inline-block;
                width: calc(100% - 60px);
                vertical-align: top;
                p{
                    span{
                        font-weight: bold;
                        font-size: 16px;
                        color: $primary_color;
                        text-transform: uppercase;
                    }
                }
            }
          .advantage_icon{
            @include box-icon(45px);
            font-size: 18px;
            margin-right: 5px;
            text-align:center;
            display: inline-block;
            img{
              width:25px;
            }
          }


        }
        
    }
}
.order_wrap{
    margin-top: $m_top;
    .order_title{
        @include titler(transparent,$primary_color);
        text-align: center;
        font-size: 24px;
    }
    .order-items{
        @include flexer;
        margin-top: $m_top;
    }
    .order-item{
        background: $primary_color;
        width: 20px;
        height: 20px;
    }
    
}
.wrap{
    margin-top: $m_top;
}
.main_description{
    p{
        font-size: 16px;
    }
}
//main-part end

// tabs start
.product-item .product-announce, .product-item .cta{
    position: initial;
}
.nav-tabs{
    border:none;
    display: flex;
    justify-content:space-between;
    li{
        margin:0;
        float:none;
        display:inline-block;
        width: 100%;
        text-align:center;
        transition:all .2s ease;
        border-bottom: 2px solid transparent;
        color:$primary_color;

        &:hover{
            border-color:$primary_color;
            a{
                color:$primary_color;
                &:hover{
                    color:$primary_color;

                }
            }
        }
        a{
            border:none;
            font-size: 18px;
            color:$primary_color;
            &:hover,&:focus{
                border:none;
                background:none;
                color: $primary_color;
            }
        }
    }
    li.active{
        border-color: $primary_color;
        a{
            border: none;
            color: $primary_color;
            &:hover, &:focus {
                border: none;
                background: none;
                color: $primary_color;
            }
        }
    }
}

#input-cost{
    padding: 20px 0;
}
.product-item{
    margin-bottom:20px;
    cursor:pointer;
    a{
        color:$primary_color;
        transition: color .4s ease;
        &:hover{
            //color:$red_color;
        }

    }
    .product-image{
        height: 250px;
    }
    .cta{
        text-align: right;
        .btn.btn-add-to-cart, .btn-add-to-cart{
            border-color: $primary_color;
            color:$primary_color;
            background:transparent;
            &:focus,&:active{
                border-color: $primary_color;
                color:$primary_color;
                background:transparent;
            }
            &:hover{
                //background: $red_color;
                //border-color:$red_color;
                background:$primary_color;
                color:$on_primary_color;
            }
        }
    }
    .product{
        font-size: 18px;
        .product-title{
            min-height: 50px;
        }

        .product-price{
            text-align:right;
            font-weight: bold;
            color:$default_color;
        }

    }

}

// tabs end

// modular product start

.modular-items{
    margin-bottom: 20px;
    table{
        max-width: 990px;
    }

    &:after{

        content: '';
        display: block;
        width: 1px;
        height: 1px;
        visibility: hidden;
        clear: both;
    }
}

.modular_item_wrap{
    margin-bottom: 10px;

    .product-image{
        img{
            //width:100%;
            width:auto;
        }
    }

    .item-name{
        //font-size: 20px;
        font-size: 18px;
    }

    .item-info{
        display: flex;
        margin-bottom: 10px;
        .item-choice{
            flex-grow: 1;
        }
        .item-price{
            flex-grow: 1;
            text-align: right;
            font-size: 18px;

        }
    }

    .item-count{
        .item-count-wrap{
            display: flex;
            justify-content: space-around;

            .item-count-btn{
                width:35px;
                height:35px;
                font-size: 18px;
                text-align: center;
                line-height: 35px;
                background: $primary_color;
                color:$on_primary_color;
                //border-radius:50%;
                cursor: pointer;
            }

            .count-input{
                position: relative;

                input{
                    max-width:70px;
                    height:35px;
                    font-size: 20px;
                    text-align: center;
                }

                &:after{
                    content: '';
                    position: absolute;
                    width:100%;
                    height:100%;
                    left: 0;
                    top:0;
                    background: transparent;
                }
            }
        }
    }

    .item-btn{
        display: none;
    }

    .price_wrap{
        font-size: 21px;
        //flex-shrink: 1;
        //flex-grow: 0;
    }
}


.component-choose-button{
    max-width:200px;
    width:100%;
    //border-radius: 0;
    background:$primary_color;
    color:$on_primary_color;
    border-color: $primary_color;
    margin:0 auto;
    margin-bottom: 10px;
    font-size: 18px;
    padding: 10px 0;
    cursor: pointer;
    &:hover,&:active,&:focus{
        background:$button_hover;
        border-color:$button_hover;
    }
}


// modular product end



// Product cart start
.product-params-wrap{
    .properties-widget{
        dl{
            display: flex;

        }
        dd,dt{
            float: none;
        }
        dt{
            max-width: 210px;
            width: 100%;
            font-size: 18px;
        }

        .product-params{
            width:calc(100% - 125px);
            dd{
                width:100%;
                font-size: 18px;
            }
        }
    }
}
.btn-add-to-wishlist{
    display:none;
}

//properties start

.properties_wrap{

    .property_item{
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        &:nth-child(odd){
            background: $grey_zebra;
        }

        .property_name{
            margin: 0;
            font-size: 18px;
            font-weight: bold;
            display: inline-block;
        }
        .property_value{
            display: inline-block;
            margin-left: 10px;
            font-size: 16px;
        }
      &.no_flex{
        display: block;
        .property_name{
          display: block;
        }
        .property_value{
          display: block;
          padding: 10px 0;
        }
        .prod_color_wrap{
          text-align: center;
          display: inline-block;
          vertical-align: top;
        }
      }
    }
}

//properties end

// variational products start

.variational_product{
    paddind:0 10px;
    .variational_product_title{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .variations_wrap{
        margin-bottom: 10px;
        padding: 0 10px;
        padding-bottom: 10px;
        border-bottom:1px solid $grey_border;

        h4{
            margin-bottom: 5px;
        }
        .propLink{
            margin-right:10px;
            &.blocks{
                display: block;
            }

            div{
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
}

// variational products end

// concomitant products start
.concomitant_title{
    font-size: 24px;
    font-weight:bold;
}
.concomitant_wrap{
    padding:0 40px;
    .concomitant_item{

        a div{
            //height:200px;

            img{
                max-width:200px;
                max-height: 200px;
                margin: 0 auto;
            }
        }
        .related_img{
            width:250px;
            height:250px;
            margin:0 auto;
            background-size:cover!important;
            background-repeat: no-repeat !important;
            background-position: center center !important;
        }
        .related_name{
            font-size: 20px;
            text-align: center;
        }
    }


    .slick-prev:before, .slick-next:before{
        color:$primary_color;
    }
}

.related_bottom{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0;
    .btn.btn-add-to-cart{
        max-width:200px;
        width:100%;
        //border-radius: 0;
        background:$primary_color;
        color:$on_primary_color;
        border-color: $primary_color;
        margin-bottom: 10px;
        &:hover,&:active,&:focus{
            background:$button_hover;
            border-color:$button_hover;
        }
    }
    .price{
        font-weight: bold;
        color: #404148;
    }

}


// concomitant products end
.low_padding{
    padding: 0 5px;
}

// Product cart end



// Product list start
.product-item{
    height: auto !important;
    .product-image{
        img{
            max-width:100%;
            width:100% !important;
        }
    }
    .product{
        position: initial !important;
    }
    .product-name{
        width:100% !important;
        float: none !important;
        display: block;
    }
    .product-announce{
        display: none;
    }
    .product-price{
        width:100% !important;
    }
    .cta{
        position: initial !important;
        width:100% !important;
    }
}

.block-product-list{
    clear: both;
    .row{
        padding: 0 15px;
    }

    .col-md-4{
        padding: 0 5px;
    }
}

// Product list end




//pre foot start
.map{
    iframe{
        width:100%;
        height: $map_height;
    }
}
.map_wrap>div{
    display: inline-block;
    vertical-align: middle;
    width: 49%;
}  
.map_description{
    vertical-align: middle;
    //padding: 60px 0;
    .map_description_item{
        display: flex;
        div{
            display: inline-block;
            vertical-align: top;
            margin-bottom: 15px;
            margin-right: 10px;
            text-transform: uppercase;
            i{
                @include box-icon;
            }
        }
    }
}

//pre foot end

// cart start
.cart{
    #cart-table{
        input.quantity{
            border-radius:0;
            border-left: 0;
            text-align: center;
        }
    }
    td{
        text-align: center;
        vertical-align: middle !important;

    }
    .btn-primary, .btn-compare{
        border-color: $primary_color;
        color:$primary_color;
        &:hover,&:focus,&:visited,&:active{
            background:$primary_color;
            border-color: $primary_color;
            color:$on_primary_color;
        }
    }

    .label-info{
        background:$primary_color;
        display:inline-block;
        padding: 5px 10px;
        font-size:21px;
    }
    .cta{
        margin-bottom: 20px;
    }
}
.product-show .cta .btn.btn-add-to-cart{
    max-width:300px;
    width:100%;
    border-radius: 0;
    background:transparent;
    color:$primary_color;
    border-color: $primary_color;
    margin-bottom: 10px;
    transition: all linear .45s;
    &:active,&:focus{
        background:$primary_color;
        color:$on_primary_color;
        border-color: $primary_color;
    }
    &:hover{
        background:$primary_color;
        color:$on_primary_color;
        border-color: $primary_color;
        box-shadow: 0 0 35px 4px $primary_color;
    }
}
.price-name .price{
    color:$active_link;
    margin-top: 20px;
}

// cart end



// footer start
#footer{
    display: none;
    .socials{
        img{
            width:40px;
        }
    }
    
    // for container
    padding-left: 15px;
    padding-right: 15px;
    background: $primary_background;
    color: #222;
    .footer{
        padding-top: 30px;
        padding-bottom: 30px;
    .foot-logo{
        .logo{
            margin: 0;
        }
    }
    .foot-director{
        @include buttons;
    }
    .foot-menu-title{
        @include titler(transparent, $primary_color);
        font-size: 16px;
    }
    .navigation-widget{ //.foot-menu
        @include lists;
        text-transform: none;
        font-size: 14px;
        font-weight: normal;
        a{
            @include links(#000);
            &:hover{
                text-decoration: underline;
            }
        }
        
    }
    
    .footer-contacts{
        i{
            @include box-icon;
        }
        >div{
            display: inline-block;
            vertical-align: top;
        }
        p{
            color: $primary_color;
            a{
                @include links
            }
        }
        .foot-messangers img{
            width: 20px;
        }
        .foot-address{
            width: calc(100% - 45px);
        }
    }
}
}
// footer end

// new footer start
#new_footer{
    color:$on_primary_color;
    background: $grey_background;
    .top-footer{
        padding: 25px 0;
        ul{
            max-width:1400px;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            list-style:none;
            li{
                margin-right:10px;
            }
        }
    }
    a{
        color:$on_primary_color;
        font-size: 16px;
    }
}


// new footer end



// product item start
.content-part{
    .product-show{
        .product-images{
            .first-image{
                display:block;
                height:555px;


                img{
                    width:100%;
                    max-height: 555px;
                }
            }



        }
    }
}

.other-images{
    .other_img{
        height:100%;
        background-size: 100% 100% !important;
    }
}

.selects{
    .select_item{
        display: inline-block;
        margin:1px;
        padding: 5px 1px 0;
        cursor:pointer;
        &.selected{
            border:1px solid $lightgrey_color;
        }
        &:hover{
            border:1px solid $lightgrey_color;
        }
    }
}

.propLink{
    img{
        width:50px;
        height:50px;
        border:1px solid $grey_border;
    }
}

// reviews start

.review{
    margin-left: 0;
    margin-right: 0;
    .label-default{
        background-color:$primary_color;
    }

}
.widget-reviews{
    .summary{
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom:1px solid $grey_border
    }
}

// reviews end

//right info start

.right_info_wrap{


    .info_title {
        margin: 0;
        padding: 5px 10px;
        background: $lightyellow_color;
        border-radius: 10px;
        color: $primary_color;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;

    }
    .info_description{
        padding: 5px 15px;
        &.delivery_info{
            padding: 5px 30px;
        }
        p{
            color:$primary_color;
            font-size: 14px;
            margin:0;
            position: relative;
        }

    }

    .delivery_info{
        p{
            &:before{
                position: absolute;
                content: '';
                width:15px;
                height:15px;
                background-size:contain;
                left:-20px;
                top:3px;
            }
        }
    }

    p.nposhta:before{
        background:url('/img/icons/nposhta.png') no-repeat;
        background-size:contain;
    }
    p.uposhta:before{
        background:url('/img/icons/point.png') no-repeat;
        background-size: contain;
    }
    p.delivery_term:before{
        background:url('/img/icons/truck.png') no-repeat;
        background-size: contain;
    }


}

.product-show .cta{
    margin-bottom: 20px;
}
.product-params-wrap{
    padding-top: 70px;
}


//right info end

.product-item{
    position: relative;
}

.cta{

    .added_message{
        padding: 10px 5px;
        border:2px solid $primary_color;
        color: $text_color;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        max-width:250px;
        position: absolute;
        bottom:0;
        right:0;
        background: $secondary_background;
        display:none;
    }
}


.review-author{
    background:$hard_grey;
    padding: 5px;
    //margin-bottom: 10px;
    margin-bottom: 0 !important;
    border-radius: 10px;
    color:$primary_color;
    i{color:#404148;}

}
.review-date_submitted{
    padding: 5px;
    border-radius: 10px;
}
.review_col{
    background: $lightyellow_color;
}
.user_col, .review_col{
    padding: 15px 10px;
}
.review_col{
    border-radius: 10px;
}
#review-form{
    
    h2{
        
        small{
            display: block;
        }
    }
}

.prod_color_wrap{
    position: relative;
    width:80px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
    .zoom_color{
        display:none;
        width:120px;
        height:120px;
        line-height: 120px;
        text-align: center;
        position:absolute;
        border-radius:10px;
        z-index: 10;
        top:-130px;
        left: -20px;
        background: $lightyellow_color;
        img{
            width:100px;
            height:100px;
        }
    }
    &:hover{
        .zoom_color{
            display: block
        }
    }
    .prod_color_title{
        margin: 0;
        line-height: 1;
        padding: 5px 0;
    }
}

.custom_image_widget{
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    justify-content: flex-start;
    align-items: center;
    div{
        display:inline-block;
        //margin-right: 15px;
        //width: calc(25% - 16px);
        max-width:336px;
        width:100%;
        text-align: center;
        margin-bottom: 10px;
        a{
            display: block;
        }
        img{
            //width:300px;
            max-height: 255px;
            width:auto;
            max-width: 100%;

        }
    }
}


// product item end

//product list start

.product-list-item{
    &-img{
        display:inline-block;
        width:30%;
        img{
            max-width: 100%;
        }
    }

    &-right{
        display: inline-block;
        vertical-align: middle;
        width:69%;
    }
    &-link{
        display:inline-block;
        width:49%;
        text-align: center;
    }
    &-info{
        display:inline-block;
        width:49%;
        text-align: right;
        h3{
            margin:0;
        }
    }
    .btn-small{
        display: none;
    }


}

.control-group{
    display: inline-block;
    margin-right: 10px;
}

#blockView .row .col-md-6{
    padding-top:10px;
    padding-bottom: 10px;
    &:hover{
        box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.75);
    }
}

img.prod_color{
    width:40px;
    height:40px;
}

.user_preferences{
    display: flex;
    //justify-content: space-between;
    justify-content: center;
    margin-bottom: 10px;

    .pull-right{
        display: none;
    }
}


//product list end


//modal start
//.modal-dialog{
//    margin:100px auto;
//}
.mebli_modals{

    .form_title{
        font-size: 25px;
        text-align: center;
        margin-bottom: 10px;
        color: $primary_color;
        font-weight: bold;
    }
    .form_description{
        text-align: center;
    }
    .form_content{
        padding: 20px 40px;
    }
    label{
        display: block;
        width:420px;
        margin: 0 auto;
    }
    .inputs{
        display: block;
        height: 40px;
        padding: 0 10px;
        box-sizing:border-box;
        width:100%;
        margin-bottom: 15px;
        color:$primary_color;
        border:1px solid $lightgrey_color;
        border-radius: 3px;
    }
    .submit_form{
        @include buttons();
        display: block;
        width:420px;
        margin: 10px auto 0;
        padding: 10px 0;
    }
    .form_content{
        textarea{
            width:100%;
            height:120px;
        }
    }


}

//modal end


// characteristics start
.properties_title_wrap{
  //background:$lightgrey_color;
    background: $primary_background;
  padding: 10px;
  //border-radius: 5px 5px 0 0;
  font-size: 18px;
  //text-transform: uppercase;
}
.properties_wrap_tab{
  .property_item{
    padding-left: 10px;
    padding-right:10px;
    .property_name{
        padding-top: 10px;
        padding-bottom: 10px;
        color: $primary_color;
        font-size: 20px;

    }
    .property_value{
        padding-top: 10px;
        padding-bottom: 10px;
        background: $lightyellow_color;
        border-radius: 0 0 5px 5px;
        font-size: 16px;
        border-radius: 0;

    }
  }
}
.prop_after{
  position: relative;
  &.active{
    //&:after{
    //  content: '';
    //  position: absolute;
    //  width:100%;
    //  height:20px;
    //  left: 0;
    //  background: $lightgrey_color;
    //}
  }
}




// characteristics end

// map start

.my_map{
    width:100%;
    height:450px;
}

// map end

//cart stage start
.cart_title{
    text-align: center;
}


#shop-stage{
    h2{
        text-align: center;
    }
    .stage_buttons{

        .btn.btn-primary{
            background:$primary_color;
            transition: all ease .3s;
            border-color:$primary_color;
            color:$on_primary_color;

            &:hover{
                background: $button_hover;
                border-color:$button_hover;
                color:$on_primary_color;
            }
        }

        .list-stage-buttons{
            li{
                text-align: center;


            }
        }
    }

}


.success_btns{
    .btn-info{
        background: $primary_color;
        transition: all ease .3s;
        border-color:$primary_color;
        color:$on_primary_color;

        &:hover{
            background:$button_hover;
            border-color: $button_hover;
            color:$on_primary_color;
        }
    }
}


//cart stage end


.carousel-inner > .item > img, .carousel-inner > .item > a > img{
    //height:450px;
    width:100%;
}


//product owl start
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot{
    font-size: 60px;
    width:50px;
    height:50px;
}
.owl-carousel .owl-nav button span{
    display: block;
}

.owl-carousel{

    .owl-nav{

        button{
            &:focus{
                outline:none;
            }
        }
    }
}

//product owl end

//product_main_info start
.product-show{
    .product_main_info{

        padding: 0 15px;
        .product_title{
            color: $primary_color;
            line-height: 1;
            font-size: 35px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
        }

        .flex_info{
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            font-weight: bold;
        }


        .product_main_price_block{
            display: flex;
            justify-content: space-between;

            padding: 50px 0;
            .price-name{

                .price{
                    color: $product_price;
                    font-size: 29px;
                    font-weight: bold;
                }
            }

            margin-bottom: 20px;
        }

        .product_cart_wrapper{
            position: relative;

            .added_message{
                padding: 20px 5px;
                border: 2px solid $add_message_color;
                font-size: 14px;
                font-weight: bold;
                line-height: 1;
                text-align: center;
                max-width: 300px;
                width: 100%;
                position: absolute;
                left: 0;
                bottom:0;
                background: $add_message_color;
                display: none;
                color:$on_primary_color;
                -webkit-box-shadow: 0 0 35px 4px $add_message_color;
                box-shadow: 0 0 35px 4px $add_message_color;
            }
        }
        .cta{
            text-align: left;
        }

    }
    .tabs_wrap{
        word-break: break-word;
    }


    .counter_wrap{
        display: flex;
        justify-content: space-between;
        //flex-grow: 1;
        //flex-shrink: 1;
        //flex-basis: 0;

        .counter_btn{
            width:40px;
            height:40px;
            line-height: 37px;
            font-size: 18px;
            color:$primary_color;
            border:3px solid $primary_color;
            transition: all ease .45s;
            text-align: center;
            font-weight: bold;
            cursor: pointer;
            margin-right:10px;

            &:hover{
                color:$on_primary_color;
                background: $primary_color;
            }

        }

        .counter_input{
            margin-right: 10px;
            input{
                width:80px;
                height:40px;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
            }


            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

    }


    .optional_information_wrap{

        display: flex;
        justify-content: space-between;
        padding: 40px 20px ;
        background:$lightyellow_color;

        .optional_information_item{
            padding: 0 40px;
            position: relative;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
            margin-right: 20px;

            &:before{
                content: '';
                width:30px;
                height:20px;
                position: absolute;
                top:0;
                left: 0;
            }
            &.truck{
                &:before{
                    background:url('/img/product/delivery-truck.png')no-repeat;
                    background-size: 100% 100%;
                }
            }
            &.card{
                &:before{
                    background:url('/img/product/credit-cards-payment.png')no-repeat;
                    background-size: 100% 100%;
                }
            }

            .title{
                font-size: 16px;
                font-weight: bold;
                color:$primary_color;
                margin-bottom: 10px;

            }
            .description{

            }
        }
    }

}





//product_main_info end



.tracker{
    background: transparent !important;
}