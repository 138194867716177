@mixin links($color:$on_primary_color, $active_color:$default_color){
    color: $color;
    text-decoration: none;
    transition:all ease .45s;
    &:hover{
        text-decoration: none;
        color: $active_color;
    }
    &:focus{
        text-decoration: none;
        color: $active_color;
    }
    &:active{
        text-decoration: none;
        color: $active_color;
    }
}

@mixin buttons($color:$primary_color, $text_color:#fff,$border_color:$color){
    border: 1px solid $border_color;
    border-radius: 3px;
    color: $color;
    background: transparent;
    padding: 5px 15px;
    transition: all .3s ease;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    &:hover,&:focus,&:active{
        background: $color;
        color:$text_color;
        border-color:$border_color;
    }
}
@mixin inline_buttons($init_color:$primary_color,$color:$primary_color){
  color:$init_color;
  display:inline-block;
  //padding:5px 15px;
  padding:5px 0px;
  transition: all .3s ease;
  &:hover{
    color:$color;
    text-decoration:underline;
  }
}
@mixin inline-list{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
        display: inline-block;
        padding: 5px 5px;
    }
}
@mixin rounded-border($round:5px){
    border-radius: $round;
}
@mixin fawesome-before($content,$top:0,$right:0,$bottom:0,$left:0,$color:#333,$width:10px,$height:10px){
    position: absolute;
    z-index: 10;
    width: $width;
//    height: $height;
    top:$top;
    right: $right;
    bottom: $bottom;
    left: $left;
    content: $content;
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    color: $color;
}

@mixin titler($background:$primary_color, $color:$on_primary_color, $paddingX:10px,$paddingY:5px,$weight:bold){
    margin: 0;
    padding: $paddingY $paddingX;
    background:$background;
    color: $color;
    font-weight: $weight !important;


}

@mixin lists($list-font:18px,$list-weight:normal,$text_transform:uppercase,$pt:5px){
    list-style: none;
    padding-top: $pt;
    font-size:$list-font;
    text-transform: $text_transform;
    font-weight: $list-weight;
    li{
        padding-left: 15px;
    }
}

@mixin active_link{
    background: $primary_color;
    color: $on_primary_color;
}
@mixin flexer{
    display: flex;
    justify-content: space-between;
}

@mixin box-icon($width:40px, $background:$primary_color, $color:$on_primary_color){
    background: $background;
    color: $color;
    padding: 10px;
    @include rounded-border(5px);
    width: $width;
    text-align: center;
}

@mixin b_radius($radius:5px){
    border-radius:$radius;
}

